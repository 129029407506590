import axios from 'axios';

const CancelToken = axios.CancelToken;

export function createCancelTokenHandler(propertyName) {
  // initializing the cancel token handler object
  let cancelTokenHandler = {};

  // initializing the cancel token of the request
  const cancelTokenRequestHandler = {
    cancelToken: undefined
  }

  // associating the cancel token handler to the request name
  cancelTokenHandler = {
    handleRequestCancellation: () => {
      // if a previous cancel token exists,
      // cancel the request
      cancelTokenRequestHandler.cancelToken && cancelTokenRequestHandler.cancelToken.cancel(`${propertyName} canceled`)

      // creating a new cancel token
      cancelTokenRequestHandler.cancelToken = CancelToken.source();

      // returning the new cancel token
      return cancelTokenRequestHandler.cancelToken;
    }
  }

  return cancelTokenHandler;
}

export function makeQuery (page = 1, filters = {}, sorts = []) {
  let queries = []

  if (page > 1) queries.push(`page=${page}`)

  const filterKeys = Object.keys(filters)
  if (filterKeys.length) {
    filterKeys.forEach((key) => {
      if (filters[key] || filters[key] === false) {
        queries.push(`${key}=${filters[key]}`)
      }
    })
  }

  if (sorts.length) queries = queries.concat(...sorts)

  return queries
}

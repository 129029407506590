<template>
  <div class="u-input custom-file-input">
    <label class="u-input-box" :class="[{'is-not-valid': !data.isValid, 'preview-image': preview, 'disabled': disabled}, classes || {}]" :style="getStyles">
<!--      <span class="input-label" :class="{'is-focus': data.value.toString().length || label.length}">{{ labelPlaceholder || label }}</span>-->

      <div class="u-input-label-box">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="u-input-label text-subtitle" :class="[{'is-focus': data.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <div class="upload-label" :style="disabled ? 'z-index: 1;' : ''">
        <button v-if="data.value.length === 0" class="upload-btn">
          <custom-icon v-if="!disabled" class="new-suggest-modal-button" icon="PLUS" color-code="#28C76F" height="23px" width="23px" />
        </button>
        <button class="upload-btn">{{ data.value.name }}</button>
        <vs-row v-if="data.value.length && logoUrl && preview" class="profile-section">
          <div v-if="logoUrl" @click="imagePreviewStatus = true"
               class="mx-auto">
            <lazy-image class="profile-avatar cursor-pointer"
                        :src="logoUrl"
                        :alt="data.value"/>
          </div>

          <image-preview v-if="imagePreviewStatus"
                         :image="{src: logoUrl, alt: data.value}"
                         @closed="imagePreviewStatus = false"/>
        </vs-row>
        <p v-else-if="data.value.length" class="upload-text">{{ uploadText || $t('customFileInput.uploaded') }}</p>
      </div>
      <input type="file"
             ref="fileInput"
             :accept="acceptType"
             :class="[align === 'right' ? 'text-left' : 'text-right', {'disabled': disabled}, {'rtl': !rtl}, {'ltr': rtl}]"
             @change="handleInputValue"
             @click="$emit('click')"
             :placeholder="placeholder || ''"
             :disabled="disabled">
    </label>
    <button class="close-btn" v-if="data.value && !disabled" @click="handleClearInput">
      <custom-icon icon="TIMES" height="25px" color="danger"/>
    </button>
  </div>
</template>

<script>
import CustomIcon from '../customIcon/customIcon'
import {uploadAssets} from '../../http/requests/avatar'
// import { setLogo } from "../../http/requests/teams";
// import { setTournamentLogo } from "../../http/requests/scoreCategory";
import ImagePreview from '../imagePreview/imagePreview'
import LazyImage from '@/components/lazyImage/lazyImage'

export default {
  name: 'customFileInput',
  components: {ImagePreview, LazyImage, CustomIcon},
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    rtl: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    label: {
      type: String,
      default: () => {
        return ''
      }
    },
    logoType: {
      type: String,
      default: () => {
        return ''
      }
    },
    acceptType: {
      type: String,
      default: () => {
        return 'image/*'
      }
    },
    preview: {
      type: Boolean,
      default: true
    },
    uploadText: {
      type: String,
      default: () => {
        return ''
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    labelPlaceholder: {
      type: String,
      default: () => {
        return ''
      }
    },
    labelPosition: {
      type: String,
      default: 'outline',
      validator (value) {
        return ['outline', 'outside'].includes(value)
      }
    },
    align: {
      type: String,
      default: () => {
        return 'left'
      },
      validator (value) {
        return ['left', 'right', 'center'].includes(value)
      }
    },
    isEmpty: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    regex: {
      type: RegExp,
      default: () => {
        return null
      }
    },
    value: {
      type: Object,
      default () {
        return {}
      }
    },
    classes: {
      type: [Object, Array, String],
      default () {
        return {}
      }
    },
    styles: {
      type: Object,
      default () {
        return {}
      }
    },
    type: {
      type: String,
      validator(type) {
        return ['avatars', 'company', 'services'].indexOf(type) >= 0;
      },
      default: () => {
        return 'avatars'
      }
    }
  },
  data () {
    return {
      data: {
        value: '',
        isValid: false
      },
      imagePreviewStatus: false,
      logoUrl: false
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        styles.width = '100%'
      }

      return styles
    }
  },
  created () {
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.data.isValid = true
    } else {
      this.validateValue()
    }
    this.getAssetsUrl()
  },
  methods: {
    handleInputValue (e) {
      const inputValue = e.target.files[0]
      switch (this.logoType) {
      case 'formData':
        this.data.isValid = true
        if (inputValue.size <= 512000) {
          const formData = new FormData()
          formData.append('asset', inputValue)
          // this.validateValue()
          this.$emit('input', {value: inputValue, isValid: this.data.isValid})
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('customFileInput.sizeError', {size: 512}),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
        break

      default:
        this.data.isValid = true
        if (inputValue.size <= 512000) {
          const formData = new FormData()
          formData.append('asset', inputValue)
          // this.validateValue()
          uploadAssets(formData, this.type).then((res) => {
            this.$emit('input', {value: res.data.path, isValid: this.data.isValid})
          }).catch(() => {
            this.handleClearInput()
          })
        } else {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('customFileInput.sizeError', {size: 512}),
            color: 'danger',
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400
          })
        }
        break
      }
    },
    getAssetsUrl () {
      this.logoUrl = this.data.value && this.preview ? this.$u.getAssetsUrl(this.data.value || '') : ''

      // console.log(this.logoUrl)
    },
    handleClearInput () {
      this.data.value = ''
      this.$refs.fileInput.value = null
      this.$emit('input', {value: '', isValid: this.data.isValid})
    },
    validateValue () {
      // if (this.regex !== null) {
      //     this.data.isValid = this.regex.test(this.data.value)
      // } else if (this.isEmpty) {
      //     this.data.isValid = true
      // } else {
      //     this.data.isValid = this.data.value.toString().length > 0
      // }
    },
    initValues () {
      this.data.value = this.value.value
      this.validateValue()
    }
  },
  watch: {
    value: {
      handler () {
        this.initValues()
        this.getAssetsUrl()
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  .custom-file-input {
    position: relative;
    margin: 15px 0 25px;

    label {
      display: block;
      padding: 6px 10px 6px 5px;
      border-radius: .5rem;
      transition: all .3s ease;

      &.is-not-valid {
        border-color: #b00000;
        color: #b00000;

        input {
          color: #b00000;
        }
      }

      span {
        padding: 0 5px;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -o-transition: all .3s ease;
        -ms-transition: all .3s ease;
        transition: all .3s ease;
      }

      input {
        width: 100%;
        background: transparent;
        border: none;
        outline: none !important;
        text-align: right;
        opacity: 0;

        &.disabled {
          opacity: 0;
        }
      }

      .upload-label {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        padding: 7px;

        .upload-btn {
          background-color: #ffffff;
          border: 0;
          color: inherit;
          font-size: 12px;
          width: calc(100% - 30px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          text-align: left;
        }
      }

      .suggest-modal-button, .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: 42px;
        top: 20px;
        width: 25px !important;
        height: 25px !important;
        padding: 5px;
        border: 1px solid #197ed5;
        border-radius: .5rem;
        color: #197ed5;
        background: white;
        cursor: pointer;
      }

      .new-suggest-modal-button {
        right: 5px;
        top: 5px;
        border: 1px solid #28C76F;
        color: #28C76F;

        .icon {
          // left: -4px;
        }
      }
    }

    .close-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: inherit;
      height: 25px;
      width: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
    }

    .upload-text {
      position: absolute;
      top: 10px;
      left: 5px;
    }

    .preview-image {
      height: 100px;
      width: 100%;

      input {
        height: 100%;
      }

      .new-suggest-modal-button {
        position: absolute;
        bottom: 0;
        right: calc((100% - 40px) / 2) !important;
        top: calc((100% - 40px) / 2);
        width: 40px;
        height: 40px;
        padding: 5px;
        border: 1px solid #197ed5;
        border-radius: .5rem;
        color: #197ed5;
        background: white;
        cursor: pointer;
      }

      .profile-section {
        position: absolute;
        top: 5px;
        width: calc(100% - 14px) !important;
        height: calc(100% - 10px) !important;

        .mx-auto {
          height: 100%;
        }

        .profile-avatar {
          max-width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>

import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../axios/utils";

const cancelTokenHandlerObject = {
  getStoreroomsInventory: createCancelTokenHandler('getStoreroomsInventory'),
  getStoreroomsInventoryValuation: createCancelTokenHandler('getStoreroomsInventoryValuation'),
  getStores: createCancelTokenHandler('getStores'),
  getStoresTrash: createCancelTokenHandler('getStoresTrash'),
  getStoreTransactions: createCancelTokenHandler('getStoreTransactions'),
  getStoreTurnover: createCancelTokenHandler('getStoreTurnover'),
  getStoresTurnover: createCancelTokenHandler('getStoresTurnover'),
  getStoreInventory: createCancelTokenHandler('getStoreInventory'),
  getStoreroomReceipts: createCancelTokenHandler('getStoreroomReceipts'),
  getWarehouseDeficits: createCancelTokenHandler('getWarehouseDeficits'),
  getWarehouseDeficitsTrash: createCancelTokenHandler('getWarehouseDeficitsTrash'),
  getWarehouseConversions: createCancelTokenHandler('getWarehouseConversions'),
  getWarehouseConversionsTrash: createCancelTokenHandler('getWarehouseConversionsTrash')
}

export function getStoreroomsInventory (page = 1, filters = {}, sorts = [], storeroom_id = 0) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/inventory${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoreroomsInventory'].handleRequestCancellation().token
  })
}

export function exportStoreroomsInventory ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/inventory?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getStoreroomsInventoryValuation (page = 1, filters = {}, sorts = [], storeroom_id = 0) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  if (storeroom_id > 0) {
    return axios({
      url: `v1/warehousing/storerooms/${storeroom_id}/inventory-valuation${queries}`,
      method: 'get',
      // setting a cancel token and canceling the previous request of this type
      cancelToken: cancelTokenHandlerObject['getStoreroomsInventoryValuation'].handleRequestCancellation().token
    })
  } else {
    return axios({
      url: `v1/warehousing/inventory-valuation${queries}`,
      method: 'get',
      // setting a cancel token and canceling the previous request of this type
      cancelToken: cancelTokenHandlerObject['getStoreroomsInventoryValuation'].handleRequestCancellation().token
    })
  }

}

export function getStores (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/storerooms${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStores'].handleRequestCancellation().token
  })
}

export function exportStores ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/storerooms?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getStoresTrash (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${queries.join('&')}`
  return axios({
    url: `v1/warehousing/storerooms?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoresTrash'].handleRequestCancellation().token
  })
}

export function getStore (id) {
  return axios({
    url: `v1/warehousing/storerooms/${id}`,
    method: 'get'
  })
}

export function getStoreTransactions (id, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/storerooms/${id}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoreTransactions'].handleRequestCancellation().token
  })
}

export function getStoreTurnover (id, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/storerooms/${id}/turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoreTurnover'].handleRequestCancellation().token
  })
}

export function exportStoreTurnover ( id, filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/storerooms/${id}/turnover?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getStoresTurnover (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/turnover${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoresTurnover'].handleRequestCancellation().token
  })
}

export function exportStoresTurnover ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/turnover?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getStoreInventory (id, page = 1, filters = {}, sorts = [], storeroom_id = 0) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/storerooms/${id}/inventory${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoreInventory'].handleRequestCancellation().token
  })
}

export function exportStoreInventory ( id, filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/storerooms/${id}/inventory?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getStoreActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/warehousing/storerooms/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function insertStore (payload) {
  return axios({
    url: 'v1/warehousing/storerooms',
    data: payload,
    method: 'post'
  })
}

export function editStore (payload) {
  return axios.patch(`v1/warehousing/storerooms/${payload.id}`, payload)
}

export function deleteStore (id, force = false) {
  return axios({
    url: `v1/warehousing/storerooms/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreStore (id) {
  return axios({
    url: `v1/warehousing/storerooms/${id}/restore`,
    method: 'put'
  })
}

export function getStoreroomReceipts (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/receipts${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getStoreroomReceipts'].handleRequestCancellation().token
  })
}

export function exportStoreroomReceipts ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/receipts?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function getStoreroomReceipt (id) {
  return axios({
    url: `v1/warehousing/receipts/${id}`,
    method: 'get'
  })
}

export function exportStoreroomReceipt ( id, type = 'pdf') {
  return axios({
    url: `v1/warehousing/receipts/${id}/export`,
    method: 'post',
    responseType: 'blob',
  })
}

export function exportStoreroomPostalReceipt ( id, type = 'pdf') {
  return axios({
    url: `v1/warehousing/receipts/${id}/export-postal-receipt`,
    method: 'post',
    responseType: 'blob',
  })
}

export function acceptStoreroomReceipt (id, payload) {
  return axios({
    url: `v1/warehousing/receipts/${id}/accept`,
    data: payload,
    method: 'post'
  })
}

export function refreshStatusStoreroomReceipt (id, force = false) {
  return axios({
    url: `v1/warehousing/receipts/${id}/refresh-status${force ? '?force=true' : ''}`,
    method: 'post'
  })
}

export function getStoreroomReceiptAddress (id) {
  return axios({
    url: `v1/warehousing/receipts/${id}/address`,
    method: 'get'
  })
}

export function getStoreroomReceiptActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/warehousing/receipts/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function insertStoreroomReceipt (payload) {
  return axios({
    url: 'v1/warehousing/receipts',
    data: payload,
    method: 'post'
  })
}

export function editStoreroomReceipt (payload) {
  return axios.patch(`v1/warehousing/receipts/${payload.id}`, payload)
}

export function deleteStoreroomReceipt (id, force = false) {
  return axios({
    url: `v1/warehousing/receipts/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

/*export function exportStoreInventory (id) {
  return axios({
    url: `v1/warehousing/storerooms/${id}/export-inventory`,
    method: 'get',
    responseType: 'blob',
  })
}*/

export function getWarehouseDeficits (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/deficits${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getWarehouseDeficits'].handleRequestCancellation().token
  })
}

export function exportWarehouseDeficits ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/deficits?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function insertWarehouseDeficit (payload, force = false) {
  return axios({
    url: `v1/warehousing/deficits${force ? '?force=true' : ''}`,
    data: payload,
    method: 'post'
  })
}

export function getWarehouseDeficit (id) {
  return axios({
    url: `v1/warehousing/deficits/${id}`,
    method: 'get'
  })
}

export function getWarehouseDeficitsTrash (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${queries.join('&')}`
  return axios({
    url: `v1/warehousing/deficits?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getWarehouseDeficitsTrash'].handleRequestCancellation().token
  })
}

export function getWarehouseDeficitActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/warehousing/deficits/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function editWarehouseDeficit (payload, force = false) {
  return axios.patch(`v1/warehousing/deficits/${payload.id}${force ? '?force=true' : ''}`, payload)
}

export function deleteWarehouseDeficit (id, force = false) {
  return axios({
    url: `v1/warehousing/deficits/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreWarehouseDeficit (id) {
  return axios({
    url: `v1/warehousing/deficits/${id}/restore`,
    method: 'put'
  })
}

export function getWarehouseConversions (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/warehousing/conversions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getWarehouseConversions'].handleRequestCancellation().token
  })
}

export function exportWarehouseConversions ( filters = {}, sorts = [], type = 'pdf') {
  let queries = makeQuery(0, filters, sorts)

  if (queries.length > 0) queries = `&${  queries.join('&')}`

  return axios({
    url: `v1/warehousing/conversions?export=${type}${queries}`,
    method: 'get',
    responseType: 'blob',
  })
}

export function insertWarehouseConversion (payload, force = false) {
  return axios({
    url: `v1/warehousing/conversions${force ? '?force=true' : ''}`,
    data: payload,
    method: 'post'
  })
}

export function getWarehouseConversion (id) {
  return axios({
    url: `v1/warehousing/conversions/${id}`,
    method: 'get'
  })
}

export function getWarehouseConversionsTrash (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `&${queries.join('&')}`
  return axios({
    url: `v1/warehousing/conversions?trashed=true${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getWarehouseConversionsTrash'].handleRequestCancellation().token
  })
}

export function getWarehouseConversionActivitiesLog (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`
  return axios({
    url: `v1/warehousing/conversions/${id}/activity-logs${queries}`,
    method: 'get'
  })
}

export function editWarehouseConversion (payload, force = false) {
  return axios.patch(`v1/warehousing/conversions/${payload.id}${force ? '?force=true' : ''}`, payload)
}

export function deleteWarehouseConversion (id, force = false) {
  return axios({
    url: `v1/warehousing/conversions/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreWarehouseConversion (id) {
  return axios({
    url: `v1/warehousing/conversions/${id}/restore`,
    method: 'put'
  })
}

import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getShops: createCancelTokenHandler('getShops'),
  getUsers: createCancelTokenHandler('getUsers'),
  getSuggestedCategoryAttributes: createCancelTokenHandler('getSuggestedCategoryAttributes'),
  getAllCategoryAttributes: createCancelTokenHandler('getAllCategoryAttributes'),
  getAttributes: createCancelTokenHandler('getAttributes'),
  getAvailableAttributes: createCancelTokenHandler('getAvailableAttributes'),
  getProducts: createCancelTokenHandler('getProducts'),
  getProductsInventory: createCancelTokenHandler('getProductsInventory'),
  getProductsPrices: createCancelTokenHandler('getProductsPrices'),
  getPaymentGateways: createCancelTokenHandler('getPaymentGateways'),
  getOrders: createCancelTokenHandler('getOrders')
}

/* Shops */
export function getShops (page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getShops'].handleRequestCancellation().token
  })
}

export function getShop (id) {
  return axios({
    url: `v1/woo-controller/shops/${id}`,
    method: 'get'
  })
}

export function insertShop (payment) {
  return axios({
    url: 'v1/woo-controller/shops',
    data: payment,
    method: 'post'
  })
}

export function editShop (payment) {
  return axios.patch(`v1/woo-controller/shops/${payment.id}`, payment)
}

export function deleteShop (id, force = false) {
  return axios({
    url: `v1/woo-controller/shops/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreShop (id) {
  return axios({
    url: `v1/woo-controller/shops/${id}/restore`,
    method: 'put'
  })
}

/* /Shops */

/* Users */
export function getUsers (shopId, page, filters = {}, sorts = [], visible = true) {
  let queries = makeQuery(page, filters, sorts)

  queries.push(`invisible=${!visible}`)
  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${shopId}/users${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getUsers'].handleRequestCancellation().token
  })
}

export function getUser (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/users/${id}`,
    method: 'get'
  })
}

export function editUser (shopId, id, payload) {

  return axios.patch(`v1/woo-controller/shops/${shopId}/users/${id}`, payload)
}

export function deleteUser (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/users/${id}`,
    method: 'delete'
  })
}

export function getUserLogs (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/users/${id}/logs`,
    method: 'get'
  })
}

export function sendUserToShop (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/users/${id}/send-to-shop`,
    method: 'post'
  })
}

export function receiveUsers (id) {
  return axios({
    url: `v1/woo-controller/shops/${id}/get-customers`,
    method: 'get'
  })
}

export function transferUsersToUseral (shopId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/transfer-users-to-useral`,
    method: 'post'
  })
}

export function setUserVisible (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/users/${id}/visible`,
    method: 'post'
  })
}

export function setUserInvisible (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/users/${id}/invisible`,
    method: 'post'
  })
}

/* /Users */

/* Categories */
export function receiveCategories (id) {
  return axios({
    url: `v1/woo-controller/shops/${id}/get-categories`,
    method: 'get'
  })
}

export function getCategories (id, all = false) {
  const queries = []

  if (all) {
    queries.push('all=true')
  }

  return axios({
    url: `v1/woo-controller/shops/${id}/categories${queries.length ? `?${queries.join('&')}` : ''}`,
    method: 'get'
  })
}

export function getCategory (shopId, categoryId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/categories/${categoryId}`,
    method: 'get'
  })
}

export function editCategory (shopId, categoryId, payload) {
  return axios.patch(`v1/woo-controller/shops/${shopId}/categories/${categoryId}`, payload)
}

export function getSuggestedCategoryAttributes (shopId, categoryId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/categories/${categoryId}/suggested-attributes`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getSuggestedCategoryAttributes'].handleRequestCancellation().token
  })
}

export function getAllCategoryAttributes (shopId, categoryId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/categories/${categoryId}/assignable-attributes`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAllCategoryAttributes'].handleRequestCancellation().token
  })
}

export function setCategoriesMapperSetting (id, payload) {
  return axios({
    url: `v1/woo-controller/shops/${id}/categories-mapper`,
    data: {categories: payload},
    method: 'post'
  })
}

/* /Categories */

/* Attributes */
export function getAttributes (id, page, all = false, filters = {}) {
  let queries = makeQuery(page, filters)

  if (all) {
    queries.push('all=true')
  }

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${id}/attributes${queries ? queries : ''}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAttributes'].handleRequestCancellation().token
  })
}

export function getAvailableAttributes (id, filters = {}, sorts = []) {
  let queries = makeQuery(1, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${id}/available-attributes${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getAvailableAttributes'].handleRequestCancellation().token
  })
}

export function getAttribute (shopId, attributeId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/attributes/${attributeId}`,
    method: 'get'
  })
}

export function getAttributeLogs (shopId, attributeId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/attributes/${attributeId}/logs`,
    method: 'get'
  })
}

export function sendAttributeToShop (shopId, attributeId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/attributes/${attributeId}/send-to-shop`,
    method: 'post'
  })
}

export function editAttribute (shopId, attributeId, payload) {
  return axios.patch(`v1/woo-controller/shops/${shopId}/attributes/${attributeId}`, payload)
}

export function receiveAttribute (shopId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/get-attributes`,
    method: 'get'
  })
}

export function transferAttributesToUseral (shopId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/transfer-attributes-to-useral`,
    method: 'post'
  })
}

/* /Attributes */

/* Products */
export function receiveProducts (id) {
  return axios({
    url: `v1/woo-controller/shops/${id}/get-products`,
    method: 'get'
  })
}

export function getReceivedProducts (id, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (id) {
    queries.push(`shop=${id}`)
  }

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${id}/pending-products${queries}`,
    method: 'get'
  })
}

export function getProducts (shopId, page, filters = {}, sorts = [], visible = true) {
  let queries = makeQuery(page, filters, sorts)

  // if (visible) queries.push(`visible=true`)
  // else queries.push(`invisible=true`)

  queries.push(`invisible=${!visible}`)
  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProducts'].handleRequestCancellation().token
  })
}

export function getProductsInventory (shopId, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-inventory${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductsInventory'].handleRequestCancellation().token
  })
}

export function updateProductShopStock (shopId, variantId, payload) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-inventory/variants/${variantId}/update-shop-stock`,
    data: payload,
    method: 'patch'
  })
}

export function getProductsPrices (shopId, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-prices${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getProductsPrices'].handleRequestCancellation().token
  })
}

export function updateProductPriceFromShop (shopId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-prices/receive`,
    method: 'post'
  })
}

export function updateProductShopPrice (shopId, variantId, payload) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-prices/variants/${variantId}/update-shop-price`,
    data: payload,
    method: 'patch'
  })
}

export function syncProductsPriceIntoShop (shopId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-prices/sync-price`,
    method: 'post'
  })
}

export function syncProductPriceIntoShop (shopId, variantId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-prices/variants/${variantId}/sync-price`,
    method: 'post'
  })
}

export function setProductPrice (shopId, variantId, payload) {

  return axios.patch(`v1/woo-controller/shops/${shopId}/products-pricing/${variantId}`, payload)
}

export function updateProductStockFromShop (shopId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-inventory/receive`,
    method: 'post'
  })
}

export function syncProductsStockIntoShop (shopId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-inventory/sync-stock`,
    method: 'post'
  })
}

export function syncProductStockIntoShop (shopId, variantId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products-inventory/variants/${variantId}/sync-stock`,
    method: 'post'
  })
}

export function exportProductsStocks (shopId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/export-products-inventory`,
    method: 'get',
    responseType: 'blob'
  })
}

export function transferProductsToUseral (shopId) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/transfer-products-to-useral`,
    method: 'post'
  })
}

export function insertProduct (shopId, payload) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products`,
    data: payload,
    method: 'post'
  })
}

export function getProduct (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products/${id}`,
    method: 'get'
  })
}

export function sendProductIntoShop (shopId, productId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products/${productId}/send-to-shop`,
    method: 'post'
  })
}

export function getProductLogs (shopId, productId, variantId = 0) {

  if (variantId > 0) {
    return axios({
      url: `v1/woo-controller/shops/${shopId}/products/${productId}/variants/${variantId}/logs`,
      method: 'get'
    })
  } else {
    return axios({
      url: `v1/woo-controller/shops/${shopId}/products/${productId}/logs`,
      method: 'get'
    })
  }
}

export function getProductVariantLogs (shopId, productId, variantId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products/${productId}/variants/${variantId}/logs`,
    method: 'get'
  })
}

export function editProduct (shopId, id, payload, force = false) {

  return axios.patch(`v1/woo-controller/shops/${shopId}/products/${id}${force ? '?force=true' : ''}`, payload)
}

export function deleteProduct (shopId, id, force = false) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function restoreProduct (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products/${id}/restore`,
    method: 'put'
  })
}

export function deleteVariant (shopId, id, variantId, force = false) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products/${id}/variants/${variantId}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function setProductVisible (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products/${id}/visible`,
    method: 'post'
  })
}

export function setProductInvisible (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/products/${id}/invisible`,
    method: 'post'
  })
}

/* /Products */

/* Payment Gateways */
export function receivePaymentGateways (id) {
  return axios({
    url: `v1/woo-controller/shops/${id}/get-payment-gateways`,
    method: 'get'
  })
}

export function getPaymentGateways (shopId, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${shopId}/payment-gateways${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getPaymentGateways'].handleRequestCancellation().token
  })
}

export function insertPaymentGateway (shopId, payload) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/payment-gateways`,
    data: payload,
    method: 'post'
  })
}

export function getPaymentGateway (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/payment-gateways/${id}`,
    method: 'get'
  })
}

export function editPaymentGateways (shopId, id, payload) {

  return axios.patch(`v1/woo-controller/shops/${shopId}/payment-gateways/${id}`, payload)
}

export function deletePaymentGateways (shopId, id, force = false) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/payment-gateways/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

export function transferPaymentGatewayToUseral (shopId, id, payload) {
  return axios({
    url: `v1/woo-controller/shops/${shopId}/payment-gateways/${id}/transfer-to-useral`,
    data: payload,
    method: 'post'
  })
}

export function sendPaymentGatewayIntoShop (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/payment-gateways/${id}/send-to-shop`,
    method: 'post'
  })
}

export function getPaymentGatewayLogs (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/payment-gateways/${id}/logs`,
    method: 'get'
  })
}

/* /Payment Gateways */

/* Orders */
export function getOrders (shopId, page, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${queries.join('&')}`

  return axios({
    url: `v1/woo-controller/shops/${shopId}/orders${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getOrders'].handleRequestCancellation().token
  })
}

export function getOrder (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/orders/${id}`,
    method: 'get'
  })
}

export function transferOrderToUseral (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/orders/${id}/transfer-to-useral`,
    method: 'post'
  })
}

export function getOrderLogs (shopId, id) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/orders/${id}/logs`,
    method: 'get'
  })
}

export function receiveOrders (id) {
  return axios({
    url: `v1/woo-controller/shops/${id}/get-orders`,
    method: 'get'
  })
}

export function sendOrderIntoShop (shopId, orderId) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/orders/${orderId}/send-to-shop`,
    method: 'post'
  })
}

export function deleteOrder (shopId, id, force = false) {

  return axios({
    url: `v1/woo-controller/shops/${shopId}/orders/${id}${force ? '/force' : ''}`,
    method: 'delete'
  })
}

/* /Orders */

/* Settings */
export function getSettings (id, justTransfer = false) {
  return axios({
    url: `v1/woo-controller/shops/${id}/settings${justTransfer ? '?transfer=true' : ''}`,
    method: 'get'
  })
}

export function updateSettings (id, payload) {
  return axios.patch(`v1/woo-controller/shops/${id}/settings`, payload)
}

// storeroom setting
export function getStoreroomSettings (id) {
  return axios({
    url: `v1/woo-controller/shops/${id}/storerooms`,
    method: 'get'
  })
}

export function editStoreroomSettings (id, payload) {
  return axios.patch(`v1/woo-controller/shops/${id}/storerooms`, payload)
}

/* /Settings */
